@import '_mixins/pux/svg-sprites';
@import '_mixins/pux/img-sprites';
@import '_mixins/pux/css-rules';
@import '_mixins/pux/breakpoints';
@import '_variables';

@import "selectric";

body,
html {
    color: $base-color;
    font-family: $base-font;
    height: 100%;
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

body.loading {
    overflow: hidden;

    &:before {
        content: '';
        z-index: 3;
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(255, 255, 255, 0.9);
    }

    &:after {
        content: '';
        z-index: 4;
        border: 15px solid $input-border;
        /* Light grey */
        border-top: 15px solid $color-blue;
        /* Blue */
        border-radius: 50%;
        width: 90px;
        height: 90px;
        animation: loader 2s linear infinite;
        position: fixed;
        top: 50%;
        left: 50%;
        margin-top: -45px;
        margin-left: -45px;
    }
}

h1 {
    font-size: 30px;
    line-height: 40px;
    margin-top: 40px;
    margin-bottom: 30px;
    font-weight: 700;
    color: $base-color;
}

a {
    color: $base-color;
    @include transition();

    &:hover,
    &:focus {
        color: $color-blue;
        text-decoration: none;
        outline: none;
    }
}

.btn {
    padding: 10px 25px;
    border-radius: 0;
    border: transparent;
    margin-right: 1px;
    height: 50px;
    line-height: 30px;
    @include transition();

    &-light {
        background: $light-grey;
        color: $base-color;
    }

    &-default {
        color: $base-white;
        background: $color-blue;

        &:hover {
            background-color: $base-color;
            color: $base-white;
        }
    }

    &-blue,
    &-primary {
        color: $base-white;
        background: $color-blue;

        &:hover {
            background-color: $base-color;
            color: $base-white;
        }
    }
}

.pux-container {
    max-width: 1800px;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;

    @include media(md) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

header {
    &.simple {
        padding: 40px 0;
        border-bottom: 1px solid #f2f2f2;

        .main-logo {
            display: block;
            margin: 30px auto;
        }
    }

    .navbar-collapse {
        padding-left: 0;
        padding-right: 0;
    }
}

.main-logo {
    display: block;
    width: 140px;
    height: 40px;
    background: url("../img/logo-140x40.png");
    background-size: cover !important;
    font-size: 0;
    margin: 15px auto;

    @include media(lg) {
        display: inline-block;
        vertical-align: middle;
        margin: 0 145px 0 0;
    }
}

.top-menu {
    margin-left: -15px;
    margin-right: -15px;

    @include media(lg) {
        display: inline-block;
        vertical-align: middle;
    }

    @include clearfix();

    .filter-button {
        background-color: $light-grey;
        display: block;
        width: 25%;
        height: 70px;
        float: left;
        font-size: 0;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 45px auto;

        &:not(:last-of-type) {
            border-right: 1px solid #fff;
        }

        @include media(lg) {
            width: 120px;
            height: 110px;
            background-size: auto;
        }

        &.kia {
            background-image: url('../img/logo-kia.png');
        }

        &.skoda {
            background-image: url('../img/logo-skoda.png');
        }

        &.bazaar {
            background-image: url('../img/logo-ojete.png');
        }

        &.plus {
            background-image: url('../img/logo-skodaplus.png');
        }

        &:hover,
        &.active {
            background-color: $color-blue;
        }
    }
}

.user-menu {
    display: block;
    padding: 10px 0;
    text-align: right;

    @include clearfix();

    @include media(lg) {
        display: inline-block;
        padding: 30px 0;
    }

    &-name {
        display: inline-block;
        vertical-align: middle;
        margin: 0 25px 10px 0;

        @include media(lg) {
            margin: 0 25px 0px 0;
        }
    }

    &-logout {
        display: inline-block;
        vertical-align: middle;
        margin: 0 0 10px 0;

        @include media(lg) {
            margin: 0 30px 0px 0;
        }
    }

    &-new-car {
        float: left;

        @include media(lg) {
            float: none;
        }
    }

    .navbar-toggle {
        display: block;
        border: 0;
        border-radius: 0;
        padding: 14px 15px;
        margin: 0 -15px 0 0;
        height: 50px;
        width: 62px;
        position: relative;

        @include media(md) {
            display: none;
        }

        .icon-bar {
            display: block;
            background-color: $color-blue;
            width: 32px;
            height: 4px;
            border-radius: 1px;
            @include transition(350ms);
            position: absolute;
            left: 15px;
            top: 14px;
            &:nth-of-type(2) {
                top: 23px;
                margin-top: 0px;
            }
            &:nth-of-type(3) {
                top: 32px;
                margin-top: 0px;
            }
        }
        &:not(.collapsed) {
            background-color: $color-blue;

            .icon-bar {
                background-color: $base-white;

                &:nth-of-type(1) {
                    @include transform(rotateZ(45deg));
                    top: 23px;
                }
                &:nth-of-type(2) {
                    width: 0px;
                    opacity: 0;
                }
                &:nth-of-type(3) {
                    @include transform(rotateZ(-45deg));
                    top: 23px;
                }
            }
        }
    }
}

.main-menu {
    background-color: $base-color;

    a {
        color: $base-white;
        font-size: 16px;
        line-height: 16px;
        padding: 20px 0;
        display: inline-block;
        vertical-align: middle;

        &:not(:last-of-type) {
            margin-right: 75px;
        }

        &:hover,
        &.active {
            color: $color-blue;
        }
    }
}

.second-menu {
    background-color: $light-grey;

    a {
        color: $base-color;
        font-size: 13px;
        line-height: 13px;
        padding: 20px 0;
        display: inline-block;
        vertical-align: middle;

        &:not(:last-of-type) {
            margin-right: 30px;
        }

        &:hover,
        &.active {
            color: $color-blue;
        }
    }
}

.table {
    &-wrapper {
        margin-bottom: -200px;
        margin-left: -15px;
        margin-right: -15px;
        position: relative;

        @include media(md) {
            margin-left: -20px;
            margin-right: -20px;
        }

        &-overflow {
            width: 100%;
            padding-bottom: 200px;
            overflow-y: visible !important;
            overflow-x: auto !important;

            &.scroll-wrapper {}

            &.scroll-content {
                padding-bottom: 200px !important;
                overflow-y: visible !important;
                overflow-x: auto !important;
            }
        }

        .scroll-element {
            position: absolute;
            background-color: $table-header;

            &_outer {
                position: relative;
            }

            .scroll-bar {
                position: absolute;
                background-color: $base-color;
            }

            &.scroll-x {
                bottom: 180px;
                left: 0;
                right: 0;
                height: 20px;

                .scroll-element_outer {
                    height: 20px;
                    margin: 0 24px;
                }

                .scroll-bar {
                    height: 12px;
                    top: 4px;
                }

                .scroll-arrow {
                    position: absolute;
                    top: 0;
                    display: block;
                    height: 20px;
                    width: 20px;
                    text-align: center;

                    &:before {
                        font-size: 22px;
                        line-height: 18px;
                    }

                    &_less {
                        left: 0;
                        &:before {
                            content: '<';
                        }
                    }

                    &_more {
                        right: 0;
                        &:before {
                            content: '>';
                        }
                    }

                    &:hover {
                        background-color: $base-color;
                        color: $table-header;
                    }
                }
            }
        }
    }
}

td[data-tag='ProductParameterProductNote'] {
    max-width: 200px;
}

.IE11 {
  .table {
    &-wrapper {
      margin-bottom: 0;

      &-overflow {
          padding-bottom: 150px;
      }
    }
  }
}

table {
    font-size: 11px;

    tr {
        &:nth-of-type(2n + 1) {
            background: darken($light-grey, 3);
        }
    }

    thead {
        tr {
            background: $base-white !important;

            a {
                color: $base-color !important;

                &:after {
                    font-family: 'icomoon';
                    content: '';
                    height: 10px;
                    width: 10px;
                    background-image: url('../img/arrow-db-sort.png');
                    background-size: 10px auto;
                    background-repeat: no-repeat;
                    background-position: center center;
                    vertical-align: 0.5px;
                    margin-left: 3px;
                    display: none;
                    position: absolute;
                    top: 50%;
                    margin-top: -5px;
                    right: 5px;
                }

                &.list-view-sort-asc {
                    &:after {
                        display: inline-block;
                        @include transform(rotateZ(180deg));
                    }
                }

                &.list-view-sort-desc {
                    &:after {
                        display: inline-block;
                    }
                }
            }

            th {
                background-color: $table-header;
                padding: 13px 20px 13px 10px;
                line-height: 14px;
                position: relative;

                &:not(:last-of-type) {
                    border-right: 1px solid $table-border;
                }

                &[data-tag="ProductParameterProductWeekOfProduction"] {
                    max-width: 80px;
                }

                &[data-tag="ProductStockNumber"] {
                    max-width: 75px;
                }

                &[data-tag="ProductParameterProductSupport"],
                &[data-tag="ProductParameterProductCommissioning"] {
                    max-width: 65px;
                }

                &[data-tag="ProductPresentOnAgrotec"] {
                    max-width: 60px;
                }
            }
        }
    }

    td {
        padding: 13px 20px 13px 10px;
        vertical-align: middle;
        padding-left: 7px;
        padding-right: 7px;
        line-height: 14px;
        position: relative;

        &:first-of-type {
            padding-left: 20px;
        }

        &:last-of-type {
            padding-right: 20px;
        }

        &:not(:last-of-type) {
            border-right: 1px solid $table-border;
        }

        &[data-tag="ProductParameterProductSupport"],
        &[data-tag="ProductStockNumber"],
        &[data-tag="ProductParameterProductWeekOfProduction"],
        &:nth-of-type(7),
        &:nth-of-type(9),
        &:nth-of-type(11),
        &:nth-of-type(15),
        &:nth-of-type(19) {
            max-width: 80px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &:nth-of-type(11) {
            max-width: 90px;
        }

        &.dropdown-cell {
            overflow: visible !important;
        }

        .dropdown-toggle {
            height: auto;
            font-size: 15px;
            font-weight: 700;
            line-height: 30px;
            padding: 0 10px;
            color: $base-white;
            background-color: $color-blue;
            margin-right: 0;

            &:hover,
            &:focus {
                background-color: $base-color;
                color: $base-white;
            }
        }

        .dropdown-menu {
            left: auto;
            right: 1px;
            box-shadow: none;
            border: 0;
            border-radius: 0;
            background-color: $color-blue;
            margin: 1px 0 0;
            padding: 0;
            min-width: 0;
            text-align: center;

            > li {
                > a {
                    padding: 0 10px;
                    line-height: 30px;
                    font-size: 12px;
                    color: $base-white;

                    &:hover,
                    &:focus {
                        background-color: $base-color;
                        color: $base-white;
                    }
                }
            }
        }
    }

    a {
        color: $color-blue;

        &:hover,
        &:focus {
            color: $base-color;
        }
    }
}


#productTable {
    width: 100%;

    thead {
        tr {
            th {
                &:first-of-type {
                    padding: 0 20px 0 40px;
                }
            }
        }
    }

    tbody {
        counter-reset: section;

        tr {
            td {
                &:first-of-type {
                    position: relative;
                    padding: 13px 20px 13px 40px;

                    &:before {
                        counter-increment: section;
                        content: counter(section)".";
                        position: absolute;
                        top: 50%;
                        margin-top: -20px;
                        left: 10px;
                        line-height: 40px;
                    }
                }

                &:last-of-type {
                    padding: 5px 20px 5px 20px;
                    text-align: right;
                }
            }
        }
    }
}

#summaryTable {
    tbody {
        counter-reset: section;

        tr {
            &:last-of-type {
                background-color: $base-color;

                td {
                    color: $base-white;
                    font-weight: 700;
                }
            }
        }
    }
}

.product-table-exports {
    margin-bottom: 40px;
    position: relative;
}

label {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 10px;
}

input.form-control {
    background: $base-white;
    box-shadow: none;
    border: 1px solid transparent;
    border-bottom-color: $input-border;
    border-radius: 0;
    font-size: 13px;
    line-height: 20px;
    padding: 14px 20px;
    height: auto;

    &:focus {
        box-shadow: none;
        border-bottom: 1px solid $input-border;
        outline: none;
        border-color: $base-color;
    }
}

input[type='radio'] {
    display: none;
}

input[type='radio'] + label {
    display: block;
    float: left;
    width: 50%;
    position: relative;
    margin: 0 0 0 0;
    background-color: $base-white;
    text-align: center;
    line-height: 48px;
    padding: 0;
    border: 1px solid transparent;
    border-bottom-color: $input-border;
}

input[type='radio']:checked + label {
    background-color: $color-blue;
    border-color: $color-blue;
}

.radio {
    margin: 0;
    padding: 0 5px;

    @include clearfix();

    br {
        display: none;
    }
}

input[type='checkbox'] {
    display: none;
}

.checkbox-item {
    position: relative;

    > span {
        &:before {
            content: '';
            display: inline-block;
            width: 15px;
            height: 16px;
            background-color: $base-white;
            border: 1px solid $base-white;
            border-bottom-color: $input-border;
            position: absolute;
            top: 0;
            left: 0;
        }

        &:after {
            content: '';
            display: inline-block;
            width: 9px;
            height: 9px;
            position: absolute;
            top: 3px;
            left: 3px;
        }
    }

    &.checked {
        > span {
            &:after {
                background-color: $color-blue;
            }
        }
    }

    label {
        line-height: 16px;
        padding-left: 22px;
        position: relative;
        margin-bottom: 4px;
        cursor: pointer;
    }
}

.form {
    &-fields {
        &-wrapper {
            margin-left: -15px;
            margin-right: -15px;
            overflow-x: auto;

            @include media(md) {
                margin-left: -20px;
                margin-right: -20px;
            }
        }

        &-container {
            padding-left: 15px;
            padding-right: 15px;

            @include media(md) {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }
}

.agrotec-parameter-group,
.agrotec-parameter-options,
.agrotec-upload-photo {
    background-color: $light-grey;
    padding: 20px;
    margin-bottom: 10px;
    @include transition(150ms);

    h2 {
        font-size: 24px;
        margin-top: 5px;
        margin-bottom: 20px;
    }

    > .row {
        margin-left: -1px;
        margin-right: -1px;

        [class*="col-"] {
            padding-left: 1px;
            padding-right: 1px;
        }
    }

    &:hover,
    &.focused {
        background-color: $panel-hover;
    }
}

.agrotec-parameter-options {
    br {
        display: none;
    }
}

.masonry {
    .agrotec-parameter-masonry {
        > div:last-of-type {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

.product-list,
.waiting-list {
    display: inline-block;
    width: 12px;
    height: 12px;
    line-height: 12px;
    text-align: center;
    background-color: $table-header;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 700;
    vertical-align: middle;
}

.agrotec-upload-photo {
    .DraggableImage_List {
        @include clearfix();
        margin-left: -1px;
        margin-right: -1px;
        margin-bottom: 20px;

        [class*="col-"] {
            padding-left: 1px;
            padding-right: 1px;
        }

        .pux-draggable {
            &-item {
                position: relative;

                &-inner {
                    background-color: $base-white;
                }

                &-image {
                    height: 120px;
                    display: block;
                    background-size: cover;
                    background-position: center center;
                    background-repeat: no-repeat;

                    @include media(md) {
                        height: 200px;
                    }
                }

                &-description {
                    display: block;
                    font-size: 15px;
                    line-height: 20px;
                    padding: 15px 50px 15px 20px;
                    word-break: break-all;
                }

                &-remove {
                    position: absolute;
                    right: 20px;
                    bottom: 4px;
                    font-size: 30px;
                    color: $color-blue;
                    cursor: pointer;
                }
            }
        }
    }

    #puxDraggableAttachment {
        display: none;
    }
}

.add-product-field {
    margin-bottom: 15px;
}

.add-product-error-message {
    color: #f00b1d;
    font-weight: 700;
}

.form-fields-controls {
    padding-top: 20px;
}

footer {
    // width: 100%;
    // margin-top: 320px;
    // padding: 30px 0;
    // color: #7a7a79;
    // border-top: 1px solid #f2f2f2;
    //
    // a {
    //     color: #48afc4;
    //
    // }
    display: none;
}

main {
  padding-bottom: 300px;
}

#login-page,
#forgotten-password {
    width: 100%;
    max-width: 350px;
    padding-left: 15px;
    padding-right: 15px;
    margin: 100px auto;

    h2 {
        font-weight: 700;
    }

    label {

        font-weight: 400;
        margin: 0 0 15px;
    }

    .form-group {
        margin-left: 0;
        margin-right: 0;
    }

    input {
        width: 100%;
    }

    .logon-remember-me-checkbox {
        display: none;
    }

    input[type='submit'] {
        margin-bottom: 40px;
    }

    input.form-control {
        background-color: $light-grey;
    }
}

.error-label {
    color: #bb0000;
    display: inline-block;
    margin: 10px 0;
}

.top-menu.user {

    a {
        margin-left: 30px;
    }
}

.archive-find {

    label {
        display: none;
    }

    a {
        margin-top: 24px;
    }
}

.selectric-items .disabled {
    display: none;
}

@import "helpers";

/* tooltip */

.tooltip {
    &.top &-arrow {
        border-top-color: $color-blue;
    }

    &-inner {
        background-color: $color-blue;
        color: $base-white;
        font-weight: 700;
        border-radius: 0;
    }
}

#ProductReservationDateTb {
  background-image: url('../img/calendar-bg.png');
  background-size: auto 25px !important;
  background-position: 95% 50% !important;
  background-repeat: no-repeat !important;
}

@media only print {
    @import 'agac-print.scss';
}

#productTable {

  td[data-tag='ProductParameterProductCustomer'] {
    text-overflow: inherit;
    white-space: normal;
  }
}
